import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import PrivateRoute from "../utils/PrivateRoute";
const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Component {...props} />
    </Suspense>
  );
};
const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const BookInterviewPage = Loadable(
  lazy(() => import("../pages/BecomeInterviewPage"))
);
const UserInterviews = Loadable(lazy(() => import("../pages/UserInterviews")));
const JobsPage = Loadable(lazy(() => import("../pages/JobsPage")));
const BecomeInterviewPage = Loadable(
  lazy(() => import("../pages/BecomeInterviewPage"))
);

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "/",
          element: (
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "interviewer",
          element: (
            <PrivateRoute>
              <BecomeInterviewPage />
            </PrivateRoute>
          ),
        },
        {
          path: "bookInterview",
          element: (
            <PrivateRoute>
              <BookInterviewPage />
            </PrivateRoute>
          ),
        },
        {
          path: "your-interviews",
          element: (
            <PrivateRoute>
              <UserInterviews />
            </PrivateRoute>
          ),
        },
        // {
        //   path: "reset/:email",
        //   element: (
        //     <GuestGuard>
        //       <Reset />
        //     </GuestGuard>
        //   ),
        // },
      ],
    },
    // {
    //   children: [
    //     {
    //       path: "/home",
    //       element: (
    //         <AuthGuard>
    //           <DashboardLayout />
    //         </AuthGuard>
    //       ),
    //       children: [],
    //     },
    //     {
    //       path: "community",
    //       element: (
    //         <AuthGuard>
    //           <Community />
    //         </AuthGuard>
    //       ),
    //       children: [],
    //     },
    //     {
    //       path: "create",
    //       element: (
    //         <AuthGuard>
    //           <Create />
    //         </AuthGuard>
    //       ),
    //       children: [],
    //     },
    //     {
    //       path: "profile",
    //       element: (
    //         <AuthGuard>
    //           <Profile />
    //         </AuthGuard>
    //       ),
    //       children: [],
    //     },
    //     {
    //       path: "search",
    //       element: (
    //         <AuthGuard>
    //           <Search />
    //         </AuthGuard>
    //       ),
    //       children: [],
    //     },
    //     {
    //       path: "cart",
    //       element: (
    //         <AuthGuard>
    //           <Yourcart />
    //         </AuthGuard>
    //       ),
    //       children: [],
    //     },
    //   ],
    // },
    // {
    //   path: "*",
    //   children: [
    //     { path: "404", element: <NotFound /> },
    //     { path: "*", element: <Navigate to="/404" replace /> },
    //   ],
    // },
  ]);
}

// const Login = Loadable(lazy(() => import("../pages/loginForms/Login")));
// import BookInterviewPage from "./pages/BookInterviewPage";
// import BecomeInterviewPage from "./pages/BecomeInterviewPage";
// import PrivateRoutes from "./utils/PrivateRoute";
// import UserInterviews from "./pages/UserInterviews";
// import JobsPage from "./pages/JobsPage";

// const NotFound = Loadable(lazy(() => import("../pages/404/Page404")));
