export const handlegenerateToken = async (mentorId) => {
  const response = await fetch(
    "https://my-app-backend-theta.vercel.app/generate-token",
    {
      method: "POST",
      body: JSON.stringify({
        mentorId: mentorId,
      }),
      headers: { "Content-Type": "application/json" },
    }
  );
  const mentorToken = await response.json();
  return mentorToken.mentorToken;
};
