import { createSlice } from "@reduxjs/toolkit";

const modalSlices = createSlice({
  name: "modalSlice",
  initialState: {
    signUpModal: false,
    signInModal: false,
  },
  reducers: {
    openSignupModal: (state, action) => {
      console.log(action.payload);
      state.signUpModal = action.payload;
    },
    openSignInModal: (state, action) => {
      state.signInModal = action.payload;
    },
  },
});

export const { openSignInModal, openSignupModal } = modalSlices.actions;
export default modalSlices.reducer;
