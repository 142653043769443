import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import {
  Call,
  CallControls,
  CallParticipantsList,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
} from "@stream-io/video-react-sdk";
import { getAuth } from "firebase/auth";

import { useNavigate, useParams } from "react-router-dom";
import { handlegenerateToken } from "../utils/functions";
const VedioConference = () => {
  const apiKey = "28j2rygmpama";
  const user = getAuth().currentUser;
  const navigate = useNavigate();
  const { id } = useParams();

  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);

  useEffect(() => {
    // if (!room) return;
    // if (!session.data) {
    //   return;
    // }
    const userId = user.uid;
    const client = new StreamVideoClient({
      apiKey,
      user: {
        id: userId,
        name: user.email ?? undefined,
      },
      tokenProvider: () => handlegenerateToken(user.uid),
    });
    const call = client.call("default", id);
    call.join({ create: true });
    setClient(client);
    setCall(call);

    return () => {
      call
        .leave()
        .then(() => client.disconnectUser())
        .catch(console.error);
    };
  }, [user, id]);
  return (
    <div>
      <Header />

      {client && call && (
        <div className="rounded-lg border bg-card text-card-foreground shadow-sm p-4 min-h-screen">
          <StreamVideo client={client}>
            <StreamTheme>
              <StreamCall call={call}>
                <SpeakerLayout />
                <CallControls
                  onLeave={() => {
                    navigate("/");
                  }}
                />
                <CallParticipantsList onClose={() => undefined} />
              </StreamCall>
            </StreamTheme>
          </StreamVideo>
        </div>
      )}
    </div>
  );
};

export default VedioConference;
