import React from "react";
import logo from "../assets/logo.png";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import Testimonials from "./Testimonials";
import SuccessStories from "./SuccessStories";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="container m-auto h-[20rem]">
      {/* <div className="relative isolate px-6 pt-4 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div> */}
      {/* <Testimonials /> */}
      <div className="flex justify-between pt-7">
        <div>
          <img src={logo} alt="logo" className="w-[150px]" />
          <p></p>
          <div className="flex gap-3">
            <AiFillInstagram size={30} />
            <FaFacebook size={30} />
            <FaLinkedin size={30} />
            <FaSquareXTwitter size={30} />
          </div>

          <p className="text-[#4F5665] leading-[30px] pt-3">
            &#169;2024Intervieweasy
          </p>
        </div>
        <div className="pt-6">
          <h1 className="font-bold">Product</h1>
          <p className="text-[#4F5665] leading-[30px] pt-5">Blog</p>
        </div>
        <div className="pt-6">
          <h1 className="font-bold">Engage</h1>
          <p className="text-[#4F5665] leading-[30px] pt-5">FAQ</p>
          <p className="text-[#4F5665] leading-[30px]">About us</p>
          <p className="text-[#4F5665] leading-[30px]">Privacy Policy</p>
          <p className="text-[#4F5665] leading-[30px]">Terms of service</p>
        </div>
        <div className="pt-6">
          <h1 className="font-bold">Earn money</h1>
          <Link to="/interviewer">
            <p className="text-[#4F5665] leading-[30px] pt-5">
              Become Interviewer
            </p>
          </Link>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Footer;
