import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyA5l7y5StJEZ5WS8j0sPZcqLq_aayTVPBU",
  authDomain: "interview-production.firebaseapp.com",
  projectId: "interview-production",
  storageBucket: "interview-production.appspot.com",
  messagingSenderId: "531312844602",
  appId: "1:531312844602:web:85935a4c3bd007721cfa03",
  measurementId: "G-MZC2G12CMJ",
};
const app = initializeApp(firebaseConfig);
// console.log(app);
const messaging = getMessaging(app);
// console.log(messaging);
const db = getFirestore(app);
const auth = getAuth();
// console.log(db);
export { messaging, db, auth };
