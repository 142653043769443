import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import BookInterviewPage from "./pages/BookInterviewPage";
import BecomeInterviewPage from "./pages/BecomeInterviewPage";
import PrivateRoutes from "./utils/PrivateRoute";
import UserInterviews from "./pages/UserInterviews";
import JobsPage from "./pages/JobsPage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import ProfilePage from "./pages/ProfilePage";
import AcceptedInterviewPage from "./pages/AcceptedInterviewPage";
import Router from "./routes";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { setUSerType } from "./redux/slices/userSlice";
import VedioConference from "./pages/VedioConference";

const messaging = getMessaging();
function App() {
  const dispatch = useDispatch();
  const auth = getAuth().currentUser;
  const userType = useSelector((state) => state.userSlice.userType);
  console.log(auth);
  // useEffect(() => {
  //   const requestNotificationPermission = async () => {
  //     try {
  //       const permission = await Notification.requestPermission();
  //       console.log("Notification permission status:", permission);

  //       if (permission === "granted") {
  //         // const token = await messaging.getToken();
  //         // console.log("FCM Token:", token);
  //         // return getToken(messaging, {
  //         //   vapidKey:
  //         //     "BF0LeRNGSKAcwYYUPapnPnOT0nB9EAZ26XRFcYwNpR9WiVnvD5M-6XsQSF7A0LW2PkdLr2aOA9YOZ5ou1LgylGQ",
  //         // }).then((currentToken) => {
  //         //   if (currentToken) {
  //         //     console.log("Current", currentToken);
  //         //   } else {
  //         //     console.log("Failed to generate");
  //         //   }
  //         // });
  //         try {
  //           getToken(messaging, {
  //             vapidKey:
  //               "BIFNe_UUUhJ0NAMA3yEJ2liV7Oi4DtWpa1k5OoUrLmgYBxDSNO7aGwfvkv0Veh745dAY55g2J3D6O8p_QeRynOc",
  //           }).then((currentToken) => {
  //             if (currentToken) {
  //               console.log(currentToken);
  //             }
  //           });
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error obtaining notification permission:", error);
  //     }
  //   };
  //   requestNotificationPermission();
  //   onMessage(messaging, (payload) => {
  //     console.log(payload);
  //   });
  // }, []);

  useEffect(() => {
    getAuth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const { email, uid } = authUser;
        const userRef = doc(db, "users", uid);
        const userType = (await getDoc(userRef)).data();
        dispatch(setUSerType(userType));
        // setIsinterviewer(Interviewer);
        // console.log(Interviewer);
        // dispatch(setUser({ email, uid }));
      }
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      {/* /* <Route path="/interviewer" element={<BecomeInterviewPage />} /> */}
      {userType === "Normal" ? (
        <>
          <Route
            path="/bookInterview"
            element={
              <PrivateRoutes>
                <BookInterviewPage />
              </PrivateRoutes>
            }
          />
          <Route
            path="/interviewer"
            element={
              <PrivateRoutes>
                <BecomeInterviewPage />
              </PrivateRoutes>
            }
          />
          <Route
            path="/your-interviews"
            element={
              <PrivateRoutes>
                <UserInterviews />
              </PrivateRoutes>
            }
          />

          <Route
            path="/profile"
            element={
              <PrivateRoutes>
                <ProfilePage />
              </PrivateRoutes>
            }
          />
          <Route
            path="/accepted"
            element={
              <PrivateRoutes>
                <AcceptedInterviewPage />
              </PrivateRoutes>
            }
          />
          <Route element={<PrivateRoutes />}>
            <Route path="/interviewer" element={<BecomeInterviewPage />} />
          </Route>
        </>
      ) : (
        <>
          <Route
            path="/jobs"
            element={
              <PrivateRoutes>
                <JobsPage />
              </PrivateRoutes>
            }
          />
        </>
      )}
      <Route
        path="/room/:id"
        element={
          <PrivateRoutes>
            <VedioConference />
          </PrivateRoutes>
        }
      />
    </Routes>
  );
}

export default App;
