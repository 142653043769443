import React, { useState } from "react";
import Header from "../components/Header";
import comingsoon from "../assets/SL-070720-32260-21.jpg";
import { Button, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import JobTypes from "../components/JobTypes";
import { jobs } from "../utils/data";
import JobCard from "../components/JobCard";
const JobsPage = () => {
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });
  const [errMsg, setErrMsg] = useState("");
  const [jobTitle, setJobTitle] = useState("Full-Time");
  const onSubmit = async (data) => {};
  return (
    <>
      <Header />
      {/* <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <img
        src={comingsoon}
        alt="comingsoon"
        className="h-[500px] w-[500px] object-cover m-auto"
      />
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div> */}
      <div className="container mx-auto flex flex-col md:flex-row gap-8 2xl:gap-14 bg-[#f7fdfd] px-5">
        <div className="w-full h-fit md:w-2/3 2xl:2/4 bg-white px-5 py-10 md:px-10 shadow-md">
          <div>
            <p className="text-gray-500 font-semibold text-2xl">Job Post</p>

            <form
              className="w-full mt-2 flex flex-col gap-8"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Input
                  name="jobTitle"
                  label="Job Title"
                  placeholder="eg. Software Engineer"
                  type="text"
                  // required={true}
                  register={register("jobTitle", {
                    required: "Job Title is required",
                  })}
                  error={errors.jobTitle ? errors.jobTitle?.message : ""}
                />
                {errors.jobTitle && (
                  <span role="alert" className="text-xs text-red-500 mt-0.5">
                    {errors.jobTitle?.message}
                  </span>
                )}
              </div>

              <div className="w-full flex gap-4 items-center">
                <div className={`w-1/2 mt-2`}>
                  <label className="text-gray-600 text-sm mb-1">Job Type</label>
                  <JobTypes jobTitle={jobTitle} setJobTitle={setJobTitle} />
                </div>

                <div className="w-1/2 mt-auto">
                  <Input
                    name="salary"
                    label="Salary (USD)"
                    placeholder="eg. 1500"
                    type="number"
                    register={register("salary", {
                      required: "Salary is required",
                    })}
                    error={errors.salary ? errors.salary?.message : ""}
                  />
                  {errors.salary && (
                    <span role="alert" className="text-xs text-red-500 mt-0.5">
                      {errors.salary?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="w-full flex gap-4">
                <div className="w-1/2">
                  <Input
                    name="vacancies"
                    label="No. of Vacancies"
                    placeholder="vacancies"
                    type="number"
                    register={register("vacancies", {
                      required: "Vacancies is required!",
                    })}
                    error={errors.vacancies ? errors.vacancies?.message : ""}
                  />
                  {errors.vacancies && (
                    <span role="alert" className="text-xs text-red-500 mt-0.5">
                      {errors.vacancies?.message}
                    </span>
                  )}
                </div>

                <div className="w-1/2">
                  <Input
                    name="experience"
                    label="Years of Experience"
                    placeholder="experience"
                    type="number"
                    register={register("experience", {
                      required: "Experience is required",
                    })}
                    // error={errors.experience ? errors.experience?.message : ""}
                  />
                  {errors.experience && (
                    <span role="alert" className="text-xs text-red-500 mt-0.5">
                      {errors.experience?.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <Input
                  name="location"
                  label="Job Location"
                  placeholder="eg. New York"
                  type="text"
                  register={register("location", {
                    required: "Job Location is required",
                  })}
                  error={errors.location ? errors.location?.message : ""}
                />
                {errors.location && (
                  <span role="alert" className="text-xs text-red-500 mt-0.5">
                    {errors.location?.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-gray-600 text-sm mb-1">
                  Job Description
                </label>
                <textarea
                  className="rounded border border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-base px-4 py-2 resize-none"
                  rows={4}
                  cols={6}
                  {...register("desc", {
                    required: "Job Description is required!",
                  })}
                  aria-invalid={errors.desc ? "true" : "false"}
                ></textarea>
                {errors.desc && (
                  <span role="alert" className="text-xs text-red-500 mt-0.5">
                    {errors.desc?.message}
                  </span>
                )}
              </div>

              <div className="flex flex-col">
                <label className="text-gray-600 text-sm mb-1">
                  Core Responsibilities
                </label>
                <textarea
                  className="rounded border border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-base px-4 py-2 resize-none"
                  rows={4}
                  cols={6}
                  {...register("resposibilities")}
                ></textarea>
              </div>

              {errMsg && (
                <span role="alert" className="text-sm text-red-500 mt-0.5">
                  {errMsg}
                </span>
              )}
              <div className="mt-2">
                <Button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-8 py-2 text-sm font-medium text-white hover:bg-[#1d4fd846] hover:text-[#1d4fd8] focus:outline-none "
                  variant={"bg-blue-600"}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full md:w-1/3 2xl:2/4 p-5 mt-20 md:mt-0">
          <p className="text-gray-500 font-semibold">Recent Job Post</p>

          <div className="w-full flex flex-wrap gap-6">
            {jobs.slice(0, 4).map((job, index) => {
              return <JobCard job={job} key={index} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobsPage;
