import React, { useEffect, useState } from "react";
import Header from "../components/Header";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Input,
  Stack,
  Tag,
  Text,
  Toast,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
const UserInterviews = () => {
  const user = getAuth().currentUser;
  const toast = useToast();
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [interviews, setInterviews] = useState();
  const [loading, setloading] = useState(null);
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showNotcancel = useDisclosure();
  // const [interviewer, setInterviewer] = useState();
  const [bookingParamentre, setBookingParametre] = useState(null);
  const fetchInterviews = async () => {
    // const user = getAuth().currentUser;
    console.log(user);

    if (user) {
      const userId = user.uid;
      const isInterviewer = await checkUserIsInterviewer(userId);

      // Listen to changes in the 'bookings' collection for the specific user
      if (isInterviewer) {
        try {
          // Fetch the mentor's domain information from the user document
          const mentorDocRef = doc(db, "users", userId);
          const mentorDocSnapshot = await getDoc(mentorDocRef);

          if (mentorDocSnapshot.exists()) {
            const mentorData = mentorDocSnapshot.data();
            const mentorDomain = mentorData.domain;
            console.log(mentorData);
            // Now, fetch interviews for the mentor based on their domain
            const interviewsCollection = collection(db, "bookings");
            const querySnapshot = await getDocs(
              query(
                interviewsCollection,
                // where("domain", "==", mentorDomain),
                // where("domain", ">=", mentorDomain.toLowerCase()),
                // where("domain", "<=", mentorDomain.toLowerCase() + "\uf8ff"),
                where("status", "==", "pending"),
                where("skills", "array-contains-any", mentorData.mentorSkills)
              )
            );

            const interviews = [];
            querySnapshot.forEach((doc) => {
              interviews.push({ id: doc.id, ...doc.data() });
            });

            setInterviews(interviews);
            console.log(interviews);
          } else {
            // Handle the case when the mentor document doesn't exist
            console.error("Mentor document does not exist.");
          }
        } catch (error) {
          console.error("Error fetching interviews for mentor:", error);
        }
      } else {
        console.log("no");
        onSnapshot(
          query(collection(db, "bookings"), where("userId", "==", userId)),
          (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log(data);
            setBookings(data);
            console.log(bookings);
          }
        );
      }
    }
  };
  const checkUserIsInterviewer = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        console.log(userData.Interviewer || false);
        return userData.Interviewer || false; // Return true if isInterviewer is true, otherwise false
      } else {
        // Handle the case when the user document doesn't exist
        console.error("User document does not exist.");
        return false;
      }
    } catch (error) {
      console.error("Error checking if user is an interviewer:", error);
      return false;
    }
  };

  function formatTimestamp(timestampInSeconds) {
    const dateTime = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine whether it's AM or PM
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${formattedHours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
    return `${dateTime.toDateString()} ${formattedTime}`;
  }

  const handleAcceptBooking = async (bookingId, userId) => {
    try {
      // Get the existing booking document
      const bookingDocRef = doc(db, "bookings", bookingId);
      const bookingDocSnapshot = await getDoc(bookingDocRef);
      console.log(bookingDocRef, bookingDocSnapshot);
      if (bookingDocSnapshot.exists()) {
        const bookingData = bookingDocSnapshot.data();
        console.log(bookingData);
        // Create a new document in the 'scheduledMeetings' collection
        const scheduledMeetingsCollection = collection(db, "scheduledMeetings");
        // console.log(scheduledMeetingsCollection);
        await addDoc(scheduledMeetingsCollection, {
          mentorId: user.uid,
          userId: bookingData.userId,
          dateTime: bookingData.date, // Copy other relevant details
        });
        // Update the booking status to 'confirmed'
        await updateDoc(bookingDocRef, {
          status: "confirmed",
          completed: "pending",
          acceptedBy: user.uid,
        });
        // Optionally, notify the user or perform additional actions
        await fetch(
          "https://my-app-backend-theta.vercel.app/sendAcceptNotification",
          {
            method: "POST",
            body: JSON.stringify({
              mentorId: user.uid,
              userId: bookingData.userId,
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
        // Fetch updated bookings
        fetchInterviews();
      } else {
        console.error("Booking document not found.");
      }
    } catch (error) {
      console.error("Error accepting booking:", error);
    }
    // const response = await fetch("http://localhost:3001/generate-token", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     mentorId: user.uid,
    //     userId: userId,
    //   }),
    //   headers: { "Content-Type": "application/json" },
    // });
    // const { mentorToken, userToken } = await response.json();
    // console.log(mentorToken, userToken);
  };

  const handleCancelInterview = async (booking) => {
    const bookingDocRef = doc(db, "bookings", booking.id);
    const bookingDocSnapshot = await getDoc(bookingDocRef);
    const bookingData = bookingDocSnapshot.data();
    // if (bookingData.status === "confirmed") {
    //   console.log("con");
    // }
    const currentTime = new Date().getTime();
    const interviewTime = bookingData.date.toDate().getTime();
    const timeDifference = interviewTime - currentTime;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    console.log("Hours difference:", hoursDifference);
    if (hoursDifference >= 48 && bookingData.status === "confirmed") {
      await deleteDoc(bookingDocRef);
      // await removeBooking(userId, interviewId);
      // await cancelScheduledInterview(interviewId);
      // await updateUserCancellationCount(userId);
      // await updateInterviewerAvailability(interviewerId, interviewId);

      // Add additional logic or notifications as needed

      return "Interview canceled successfully";
    } else {
      // Return an error if the cancellation is not allowed
      onClose();
      showNotcancel.onOpen();
    }
  };

  const handleJoinroom = (roomId, startTime) => {
    if (isJoinButtonEnabled(startTime) <= 5) {
      navigate(`/room/${roomId}`);
    } else {
      toast({
        title: "Please join before 5 min",
        status: "info",
      });
    }
  };

  const isJoinButtonEnabled = (startTime) => {
    const currentTime = new Date();
    const startTimeDate = startTime.toDate(); // Convert Firestore Timestamp to JavaScript Date
    const timeDifference =
      (startTimeDate.getTime() - currentTime.getTime()) / (1000 * 60);
    console.log(timeDifference);
    return timeDifference;
  };
  useEffect(() => {
    fetchInterviews();
    // checkUserIsInterviewer();
    // const socket = io("http://localhost:3001", {
    //   withCredentials: true,
    // });

    // socket.on("connect", () => {
    //   console.log("connected");
    // });
    // return () => socket.close();
  }, [user]);

  return (
    <div className="bg-slate-100 h-[100vh]">
      <Header />

      {/* {!interviews ? ( */}
      <>
        {/* <h1 className="font-bold text-center text-[2rem] pt-4">
          {interviews ? "Interview requests" : "Sheduled Interviews"}
        </h1> */}
        <div className="container m-auto flex justify-between items-center mt-8">
          {/* <h1 className="font-semibold text-[20px]">Accepted Interviews</h1> */}
          <h1 className="font-bold text-center text-[20px]">
            {interviews ? "Interview requests" : "Sheduled Interviews"}
          </h1>
          <div className="flex gap-3 w-[50%]">
            <Input placeholder="Search..." />
            <Button>Search</Button>
          </div>
        </div>
        <div className="container m-auto mt-8">
          {interviews ? (
            <ul className="flex gap-5 flex-wrap">
              {interviews.map((e, i) => (
                // <ul
                //   key={e.id}
                //   className="flex flex-col gap-2 border shadow-md w-[300px] p-8 rounded-md bg-white"
                // >
                //   <li>Domain:{e.domain}</li>
                //   <li>Name:{e.fullname}</li>
                //   <li>Email:{e.email}</li>
                //   <li>Mobile No:{e.phoneNumber}</li>
                //   {/* <li>Time:{formatTimestamp(e.dateTime.seconds)}</li> */}
                //   <li>
                //     Time:{new Date(e.date.seconds * 1000).toLocaleString()}
                //   </li>
                //   <ul>
                //     <p>Skills:</p>
                //     <ul className="flex gap-2 flex-wrap">
                //       {e.skills.map((e, i) => (
                //         <>
                //           <li
                //             key={i}
                //             className="bg-[#000] text-[#fff] text-[12px] rounded-md p-1"
                //           >
                //             {e}
                //           </li>
                //         </>
                //       ))}
                //     </ul>
                //   </ul>
                //   <Button
                //     colorScheme="whatsapp"
                //     onClick={() => handleAcceptBooking(e.id, e.userId)}
                //   >
                //     Accept
                //   </Button>
                // </ul>
                <Card maxW="sm" className="mt-8" key={i}>
                  <CardHeader>
                    <Heading size="md">{e.domain}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Stack spacing={2}>
                      <p>Name: {e.fullname}</p>
                      <div className="flex gap-4 flex-wrap">
                        Skills:
                        {e.skills.map((f, j) => (
                          <Tag
                            className="bg-black p-1 text-white rounded-md text-[10px]"
                            key={j}
                          >
                            {f}
                          </Tag>
                        ))}
                      </div>
                      <Text>Mobile: {e.phoneNumber}</Text>
                      <Text>
                        Time: {new Date(e.date.seconds * 1000).toLocaleString()}
                      </Text>
                      <Text>Experience: {e.experience}</Text>
                    </Stack>
                  </CardBody>
                  <CardFooter className="flex gap-3">
                    <Button
                      colorScheme="whatsapp"
                      onClick={() => handleAcceptBooking(e.id, e.userId)}
                    >
                      Accept
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </ul>
          ) : (
            <div className="flex flex-wrap gap-5">
              {bookings.map((e, i) => (
                // <li
                //   key={booking.id}
                //   className="bg-white shadow-zinc-300 shadow-md rounded-md p-10"
                // >
                //   <p className="font-bold">Domain:{booking.domain}</p>
                //   <p>Email:{booking.email}</p>
                //   <p>
                //     Time:
                //     {new Date(booking.date.seconds * 1000).toLocaleString()}
                //   </p>
                //   <p>Name:{booking.fullname}</p>
                //   <p>Mobile Number:{booking.phoneNumber}</p>
                //   <p>Price: Rs.{booking.amount}</p>
                //   <div className="flex gap-4 mt-2">
                //     {/* <Button colorScheme="twitter" size="sm">
                //       {booking.status === "pending" ? "Pending" : "Confirmed"}
                //     </Button> */}
                //     {booking.status === "pending" ? (
                //       <Button size="sm">Pending</Button>
                //     ) : (
                //       <Button
                //         size="sm"
                //         onClick={() => handleJoinroom(booking.id)}
                //       >
                //         Join
                //       </Button>
                //     )}
                //     <Button
                //       colorScheme="red"
                //       size="sm"
                //       onClick={() => {
                //         onOpen();
                //         setBookingParametre(booking);
                //       }}
                //     >
                //       Cancel
                //     </Button>
                //   </div>
                //   <AlertDialog
                //     motionPreset="slideInBottom"
                //     leastDestructiveRef={cancelRef}
                //     onClose={onClose}
                //     isOpen={isOpen}
                //     isCentered
                //   >
                //     <AlertDialogOverlay />

                //     <AlertDialogContent>
                //       <AlertDialogHeader className="text-center !pt-6">
                //         Are you sure ?
                //       </AlertDialogHeader>
                //       <AlertDialogCloseButton />
                //       <AlertDialogBody className="text-center">
                //         This action will cancel your Interview Information.
                //         You won't be able to revert this!
                //       </AlertDialogBody>
                //       <AlertDialogFooter className="!justify-center gap-3">
                //         <Button
                //           colorScheme="red"
                //           ml={3}
                //           onClick={() =>
                //             handleCancelInterview(bookingParamentre)
                //           }
                //         >
                //           Yes
                //         </Button>
                //         <Button
                //           ref={cancelRef}
                //           onClick={onClose}
                //           colorScheme="red"
                //           variant="outline"
                //         >
                //           Cancel
                //         </Button>
                //       </AlertDialogFooter>
                //     </AlertDialogContent>
                //   </AlertDialog>
                // </li>
                <>
                  <Card maxW="sm" className="mt-8" key={i}>
                    <CardHeader className="flex justify-between">
                      <Heading size="md">{e.domain}</Heading>
                      {/* <Text fontSize={"xs"}>
                        {e.status === "pending" ? "Pending" : "Confirmed"}
                      </Text> */}
                      {e.status === "pending" ? (
                        <Text fontSize={"xs"} color={"red"}>
                          Pending
                        </Text>
                      ) : (
                        <Text fontSize={"xs"} color={"green"}>
                          Confirmed
                        </Text>
                      )}
                    </CardHeader>
                    <CardBody>
                      <Stack spacing={2}>
                        <p>Name: {e.fullname}</p>
                        <div className="flex gap-4 flex-wrap">
                          Skills:
                          {e.skills.map((f, j) => (
                            <Tag
                              className="bg-black p-1 text-white rounded-md text-[10px]"
                              key={j}
                            >
                              {f}
                            </Tag>
                          ))}
                        </div>
                        <Text>Mobile: {e.phoneNumber}</Text>
                        <Text>
                          Time:{" "}
                          {new Date(e.date.seconds * 1000).toLocaleString()}
                        </Text>
                        <Text>Experience: {e.experience}</Text>
                      </Stack>
                    </CardBody>
                    <CardFooter className="flex gap-3">
                      <Button
                        size="sm"
                        onClick={() => handleJoinroom(e.id, e.date)}
                        // _disabled={!isJoinButtonEnabled(e.startTime)}
                      >
                        Join
                      </Button>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => {
                          onOpen();
                          setBookingParametre(e);
                        }}
                      >
                        Cancel
                      </Button>
                    </CardFooter>
                  </Card>
                  <AlertDialog
                    motionPreset="slideInBottom"
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                    isOpen={isOpen}
                    isCentered
                  >
                    <AlertDialogOverlay />

                    <AlertDialogContent>
                      <AlertDialogHeader className="text-center !pt-6">
                        Are you sure ?
                      </AlertDialogHeader>
                      <AlertDialogCloseButton />
                      <AlertDialogBody className="text-center">
                        This action will cancel your Interview Information. You
                        won't be able to revert this!
                      </AlertDialogBody>
                      <AlertDialogFooter className="!justify-center gap-3">
                        <Button
                          colorScheme="red"
                          ml={3}
                          onClick={() =>
                            handleCancelInterview(bookingParamentre)
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          ref={cancelRef}
                          onClick={onClose}
                          colorScheme="red"
                          variant="outline"
                        >
                          Cancel
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </>
              ))}
            </div>
          )}
        </div>
        <AlertDialog
          motionPreset="slideInBottom"
          // leastDestructiveRef={cancelRef}
          onClose={showNotcancel.onClose}
          isOpen={showNotcancel.isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader className="text-center !pt-6">
              Important Notice: Unable to Cancel Scheduled Interview
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody className="text-center">
              I regret to inform you that canceling your interview won't be
              possible.
            </AlertDialogBody>
            {/* <AlertDialogFooter className="!justify-center gap-3">
                          <Button
                            colorScheme="red"
                            ml={3}
                            onClick={() =>
                              handleCancelInterview(bookingParamentre)
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            ref={cancelRef}
                            onClick={onClose}
                            colorScheme="red"
                            variant="outline"
                          >
                            Cancel
                          </Button>
                        </AlertDialogFooter> */}
          </AlertDialogContent>
        </AlertDialog>
      </>
      {/* ) : (
        <div>
          <h1>No requests</h1>
        </div>
      )} */}
    </div>
  );
};

export default UserInterviews;
