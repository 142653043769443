import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openSignupModal } from "../redux/slices/modalSlices";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { FaRegUserCircle } from "react-icons/fa";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
// import firebase from "firebase/app";
// import "firebase/compat/auth";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
} from "firebase/auth";
import { db } from "../firebase";
import { useForm } from "react-hook-form";
import { setUser, userLogout } from "../redux/slices/userSlice";
import logo from "../assets/logo.png";
import signupmodalimg from "../assets/Tiny_people_signing_legal_principles_document-removebg-preview.png";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { MdInterpreterMode } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import { MdLogout } from "react-icons/md";
import { TiEye } from "react-icons/ti";
import { GoogleAuthProvider } from "firebase/auth";
const Header = () => {
  const navigate = useNavigate();
  const userType = useSelector((state) => state.userSlice.userType);
  const [isInterviewer, setIsinterviewer] = useState(undefined);
  const [signInPassword, setSigninPassword] = useState(false);
  const [signUpPassword, setSignUpPassword] = useState(false);
  const toast = useToast();
  const signUpmodal = useDisclosure();
  const signInModal = useDisclosure();
  const dispatch = useDispatch();
  // const user = firebase.auth().currentUser;
  // console.log(user);
  const user = useSelector((state) => state.userSlice.user);
  const auth = getAuth();
  const messaging = getMessaging();
  // console.log(auth.currentUser.uid);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1, isSubmitting: isSubmitting1 },
    reset: reset1,
  } = useForm();
  async function onSignUpSubmit(values) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      // await db.collection("users").doc(userCredential.user.uid).set({
      //   email: userCredential.user.email,
      // });
      await setDoc(doc(db, "users", userCredential.user.uid), {
        name: values.name,
        email: userCredential.user.email,
        type: "Normal",
        time: Timestamp.now(),
      });

      toast({
        title: "Registration Successfull",
        position: "bottom-right",
        isClosable: true,
        status: "success",
        duration: 2500,
      });
      reset1();
      signUpmodal.onClose();
      // setTimeout(() => {
      //   signInModal.onOpen();
      // }, 2000);
    } catch (error) {
      console.log(error);
      var errorCode = error.code;
      console.error(errorCode);
      var errorMessage = error.message;
      console.error(errorMessage);

      toast({
        title: "Try Again...",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        duration: 2000,
      });
    }
  }
  async function onsignInSubmit(values) {
    await signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        var user = userCredential.user;
        console.log(await user.getIdToken());
        requestNotificationPermission();
        // console.log(user);
        // navigate("/");
        dispatch(setUser(user));
        signInModal.onClose();
        reset();
      })
      .catch((error) => {
        var errorCode = error.code;
        console.log(errorCode);
        var errorMessage = error.message;
        console.log(errorMessage);
        if (errorCode === "auth/invalid-credential") {
          toast({
            title: "Invalid credentials",
            position: "bottom-right",
            isClosable: true,
            status: "error",
          });
        }
        // ..
      });
  }
  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      console.log(result);
      // const user = result.user;

      // Store the user's email in Firestore

      // const firestore = firebase.firestore();
      // await firestore.collection('users').doc(user.uid).set({
      //   email: user.email,
      // });
      await setDoc(doc(db, "users", result.user.uid), {
        name: result.user.displayName,
        email: result.user.email,
        type: "Normal",
        time: Timestamp.now(),
      });
      toast({
        title: "Registration Successfull",
        position: "bottom-right",
        isClosable: true,
        status: "success",
        duration: 2500,
      });
      reset1();
      signUpmodal.onClose();
    } catch (error) {
      // setError(error.message);
    }
  };
  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      console.log("Notification permission status:", permission);

      if (permission === "granted") {
        try {
          getToken(messaging, {
            vapidKey:
              "BIFNe_UUUhJ0NAMA3yEJ2liV7Oi4DtWpa1k5OoUrLmgYBxDSNO7aGwfvkv0Veh745dAY55g2J3D6O8p_QeRynOc",
          }).then(async (currentToken) => {
            if (currentToken) {
              await setDoc(
                doc(db, "users", auth.currentUser.uid),
                {
                  fcmToken: currentToken,
                },
                { merge: true }
              );
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Error obtaining notification permission:", error);
    }
  };
  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/", { replace: true });
      toast({
        title: "Logout successfull",
        position: "bottom-right",
        isClosable: true,
      });
      dispatch(userLogout());
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };
  useEffect(() => {
    getAuth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const { email, uid } = authUser;
        const userRef = doc(db, "users", uid);
        // console.log(userRef.data());
        const Interviewer = (await getDoc(userRef)).data().Interviewer;
        setIsinterviewer(Interviewer);
        // console.log(Interviewer);
        dispatch(setUser({ email, uid }));
      }
    });
  }, []);

  return (
    <>
      <div className="sticky top-0 z-[999] shadow-emerald-50 border-b-[0.2px] bg-[#fff]">
        <div className="container m-auto flex justify-between p-4 items-center">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="object-cover w-[160px] h-[60px]"
            />
          </Link>
          {user ? (
            <>
              {userType === "Normal" ? (
                <ul className="flex gap-4 items-center">
                  <Link to="/interviewer">
                    <li className="cursor-pointer font-semibold">
                      Become Interviewer
                    </li>
                  </Link>
                  <Link to="/bookInterview">
                    <li className="cursor-pointer font-semibold">
                      Book an Interview
                    </li>
                  </Link>
                  <div className="cursor-pointer">
                    <Popover>
                      <PopoverTrigger>
                        <Avatar
                          src="https://bit.ly/broken-link"
                          className=""
                          size="sm"
                          name={user.email}
                        />
                      </PopoverTrigger>
                      <PopoverContent padding="10px">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <div className="flex flex-col gap-3">
                          <div className="flex items-center">
                            <Avatar
                              src="https://bit.ly/broken-link"
                              name={user.email}
                              size="sm"
                            />
                            <h1 className="text-[15px] p-3">{user.email}</h1>
                          </div>
                          <Link
                            to="/profile"
                            className="flex items-center gap-2 pl-7 justify-center"
                          >
                            <FaRegUserCircle />
                            <p className="cursor-pointer">Your Account</p>
                          </Link>

                          <Link
                            to="/your-interviews"
                            className="flex gap-2 items-center pl-7 justify-center"
                          >
                            <MdInterpreterMode />
                            <p className="cursor-pointer">Your Interviews</p>
                          </Link>
                          {isInterviewer ? (
                            <Link
                              to="/accepted"
                              className="flex gap-2 items-center pl-7 justify-center"
                            >
                              <TiTickOutline />
                              <p className="cursor-pointer">
                                Accepted Interviews
                              </p>
                            </Link>
                          ) : null}
                          <div className="flex gap-2 items-center pl-7 justify-center">
                            <MdLogout />
                            <p className="cursor-pointer" onClick={logout}>
                              Logout
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </ul>
              ) : (
                <ul className="flex gap-4 items-center">
                  <Link to="/jobs">
                    <li className="cursor-pointer font-semibold">Post a Job</li>
                  </Link>
                  <Link to="/jobs">
                    <li className="cursor-pointer font-semibold">Your Jobs</li>
                  </Link>
                  <div className="cursor-pointer">
                    <Popover>
                      <PopoverTrigger>
                        <Avatar
                          src="https://bit.ly/broken-link"
                          className=""
                          size="sm"
                          name={user.email}
                        />
                      </PopoverTrigger>
                      <PopoverContent padding="10px">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <div className="flex flex-col gap-3">
                          <div className="flex items-center">
                            <Avatar
                              src="https://bit.ly/broken-link"
                              name={user.email}
                              size="sm"
                            />
                            <h1 className="text-[15px] p-3">{user.email}</h1>
                          </div>
                          <div className="flex gap-2 items-center pl-7 justify-center">
                            <MdLogout />
                            <p className="cursor-pointer" onClick={logout}>
                              Logout
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </ul>
              )}
            </>
          ) : (
            <ul className="flex items-center gap-5">
              {/* <li className="cursor-pointer">Become Interviewer</li>
        <li className="cursor-pointer">Book an Interview</li> */}
              {/* <p
              onClick={signInModal.onOpen}
              className="text-[1rem] font-bold cursor-pointer"
            >
              Sign In
            </p> */}
              {/* <Button
                colorScheme="#4646E5"
                bgColor="#4646E5"
                size="sm"
                onClick={signInModal.onOpen}
              >
                Sign In
              </Button> */}
              <Button
                colorScheme="#4646E5"
                bgColor="#4646E5"
                size="sm"
                onClick={signInModal.onOpen}
              >
                Sign In
              </Button>
              <Button
                // className="text-[1rem]"
                size="sm"
                colorScheme="#4646E5"
                borderColor="#4646E5"
                color="#4646E5"
                // borderRadius="100px"
                // fontWeight={600}
                // fontSize={16}
                // width={150}
                // color="#f2fc32"
                // borderColor="#fc325b"
                variant="outline"
                onClick={signUpmodal.onOpen}
              >
                Sign Up
              </Button>
            </ul>
          )}
        </div>
      </div>
      <Modal
        isOpen={signUpmodal.isOpen}
        onClose={signUpmodal.onClose}
        isCentered
        size={"lg"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          minWidth="fit-content"
          // minHeight="fit-content"
          height="450px"
          padding="0px"
          borderRadius="20px"
        >
          <ModalCloseButton onClick={() => reset1()} />
          <ModalBody padding="0px">
            <div className="flex rounded-[0.375rem] rounded-bl-[0.375rem]">
              <div className="w-[350px] h-[450px] shadow-md bg-gradient-to-tr from-[#4646E5] to-[#0a7fe7] rounded-[20px] flex items-center justify-center">
                {/* <img
                  src={signupmodalimg}
                  alt="img"
                  className="w-[200px] object-contain block m-auto"
                  // loading="lazy"
                /> */}
                <div className="p-10">
                  <h1 className="font-bold text-[2rem] text-[#fff]">
                    Welcome...
                  </h1>
                  <Text className="text-[15px] pt-2 text-[#fff] font-semibold">
                    Welcome to Intervieweasy! Elevate your interview skills,
                    whether you're a fresh graduate or a seasoned professional.
                    🚀 Join us now!"
                  </Text>
                </div>
              </div>
              <form
                onSubmit={handleSubmit1(onSignUpSubmit)}
                className="w-[450px] p-10"
              >
                <h1 className="font-bold text-[19px]">Sign up</h1>
                <FormControl>
                  <Input
                    id="name"
                    placeholder="Enter name"
                    {...register1("name", {
                      required: "name is required",
                    })}
                    className="mt-5 shadow-sm"
                  />
                  {errors1.name && (
                    <p className="text-red-500 text-[14px]">
                      *{errors1.name.message}
                    </p>
                  )}
                  <Input
                    id="email"
                    placeholder="Email"
                    {...register1("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    className="mt-5 shadow-sm"
                  />
                  {errors1.email && (
                    <p className="text-red-500 text-[14px]">
                      *{errors1.email.message}
                    </p>
                  )}
                  <InputGroup className="absolute">
                    <Input
                      id="password"
                      placeholder="Password"
                      {...register1("password", {
                        required: "Password is required",
                        minLength: {
                          value: 4,
                          message: "Minimum length should be 4",
                        },
                        maxLength: {
                          value: 8,
                          message: "Maximum length should be 8",
                        },
                      })}
                      className="mt-5 shadow-sm"
                      type={signUpPassword ? "text" : "password"}
                    />
                    <InputRightElement>
                      {/* {show ? "Hide" : "Show"} */}
                      <TiEye
                        className="relative top-5 cursor-pointer"
                        onClick={() => setSignUpPassword(!signUpPassword)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors1.password && (
                    <p className="text-red-500 text-[14px]">
                      *{errors1.password.message}
                    </p>
                  )}
                  <Button
                    onClick={handleSubmit1(onSignUpSubmit)}
                    className="mt-5 m-auto"
                    colorScheme="messenger"
                    size="sm"
                  >
                    Sign Up
                  </Button>
                </FormControl>
                {/* <Button className="mt-5">Sign up with google</Button> */}
                <Text className="mt-5 text-[14px] ">
                  Already have an account ?{" "}
                  <span
                    onClick={() => {
                      if (signUpmodal.isOpen) {
                        signUpmodal.onClose();
                      }
                      reset1();
                      signInModal.onOpen();
                    }}
                    className="font-semibold cursor-pointer"
                  >
                    Sign in
                  </span>
                </Text>
                <Button onClick={signInWithGoogle}>Sign up with google</Button>
                <Text>Sign Up as a Recruiter</Text>
              </form>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button onClick={signUpmodal.onClose}>Close</Button>
            <Button
              onClick={() => {
                if (signUpmodal.isOpen) {
                  signUpmodal.onClose();
                }
                signInModal.onOpen();
              }}
            >
              sIGN IN
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={signInModal.isOpen}
        onClose={signInModal.onClose}
        isCentered
        scrollBehavior="inside"
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          minWidth="fit-content"
          // minHeight="fit-content"
          height="450px"
          padding="0px"
          borderRadius="20px"
        >
          <ModalCloseButton onClick={() => reset()} />
          <ModalBody padding="0px">
            <div className="flex rounded-[0.375rem] rounded-bl-[0.375rem]">
              <div className="w-[350px] h-[450px] shadow-md bg-gradient-to-tr from-[#4646E5] to-[#0a7fe7] rounded-[20px] flex items-center justify-center">
                {/* <img
                  src={signupmodalimg}
                  alt="img"
                  className="w-[200px] object-contain block m-auto"
                  // loading="lazy"
                /> */}
                <div className="p-10">
                  <h1 className="font-bold text-[2rem] text-[#fff]">
                    Welcome...
                  </h1>
                  <Text className="text-[15px] pt-2 text-[#fff] font-semibold">
                    Welcome to Intervieweasy! Elevate your interview skills,
                    whether you're a fresh graduate or a seasoned professional.
                    🚀 Join us now!"
                  </Text>
                </div>
              </div>
              <form
                onSubmit={handleSubmit(onsignInSubmit)}
                className="w-[450px] p-10"
              >
                <h1 className="font-bold text-[19px]">Sign in</h1>
                <FormControl>
                  <Input
                    id="email"
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    className="mt-5 shadow-sm"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-[14px]">
                      *{errors.email.message}
                    </p>
                  )}
                  <InputGroup className="absolute">
                    <Input
                      id="password"
                      placeholder="Password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 4,
                          message: "Minimum length should be 4",
                        },
                      })}
                      className="mt-5 shadow-sm"
                      type={signInPassword ? "text" : "password"}
                    />

                    <InputRightElement>
                      {/* {show ? "Hide" : "Show"} */}
                      <TiEye
                        className="relative top-5 cursor-pointer"
                        onClick={() => setSigninPassword(!signInPassword)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <p className="text-red-500 text-[14px]">
                      *{errors.password.message}
                    </p>
                  )}
                  <Button
                    onClick={handleSubmit(onsignInSubmit)}
                    className="mt-5 m-auto"
                    colorScheme="messenger"
                    size="sm"
                  >
                    Sign in
                  </Button>
                </FormControl>
                {/* <Button className="mt-5">Sign in with google</Button> */}
                <Text className="mt-5 text-[14px] ">
                  Don't have an account ?{" "}
                  <span
                    onClick={() => {
                      if (signInModal.isOpen) {
                        signInModal.onClose();
                      }
                      reset();
                      signUpmodal.onOpen();
                    }}
                    className="font-semibold cursor-pointer"
                  >
                    Sign up
                  </span>
                </Text>
                <Text>Sign In as a Recruiter</Text>
              </form>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button onClick={signUpmodal.onClose}>Close</Button>
            <Button
              onClick={() => {
                if (signUpmodal.isOpen) {
                  signUpmodal.onClose();
                }
                signInModal.onOpen();
              }}
            >
              sIGN IN
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Header;
