import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Avatar } from "@chakra-ui/react";
const SuccessStories = () => {
  return (
    <div className="container m-auto">
      <Swiper
        loop={true}
        autoplay={true}
        slidesPerView={2}
        spaceBetween={30}
        // pagination={{
        //   clickable: true,
        // }}
        // modules={[Pagination]}
      >
        <SwiperSlide>
          <div className="bg-[#fff] rounded-md p-6 shadow-lg border">
            <div className="flex justify-between items-center">
              <div className="flex gap-3">
                <Avatar />
                <div>
                  <h3 className="font-bold">Chandu</h3>
                  <p>Electrical Designer</p>
                </div>
              </div>
              <p>4.5</p>
            </div>
            <p className="text-[#0009] pt-2">
              Chandu 2 year experience as cheif engineer. He is an excellent
              engineer with overall 5 years as industry expert.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-[#fff] rounded-md p-6 shadow-lg border">
            <div className="flex justify-between items-center ">
              <div className="flex gap-3">
                <Avatar />
                <div>
                  <h3 className="font-bold">Paramesh</h3>
                  <p>Flutter developer</p>
                </div>
              </div>
              <p>4.5</p>
            </div>
            <p className="text-[#0009] pt-2">
              Paramesh 2 year experience as flutter developer. He is an
              excellent developer with overall 2 years as industry expert.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="bg-[#fff] rounded-md p-6 shadow-lg border">
            <div className="flex justify-between items-center">
              <div className="flex gap-3">
                <Avatar />
                <div>
                  <h3 className="font-bold">Uday</h3>
                  <p>React Frontend developer</p>
                </div>
              </div>
              <p>4.5</p>
            </div>
            <p className="text-[#0009] pt-2">
              Uday 2 year experience as React developer. He is an excellent
              developer with overall 2 years as industry expert.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-[#fff] rounded-md p-6 shadow-lg border">
            <div className="flex justify-between items-center">
              <div className="flex gap-3">
                <Avatar />
                <div>
                  <h3 className="font-bold">Satish</h3>
                  <p>Architect</p>
                </div>
              </div>
              <p>4.5</p>
            </div>
            <p className="pt-2 text-[#0009]">
              Satish 2 year experience as cheif architect. He is an excellent
              architect with overall 2 years as industry expert.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SuccessStories;
