import { createSlice } from "@reduxjs/toolkit";
const date = new Date();
console.log(date);
const bookInterviewSlice = createSlice({
  name: "bookInterview",
  initialState: {
    price: 0,
    fullName: "",
    email: "",
    domain: "",
    phoneNumber: "",
    dateTime: date.toISOString(),
    experience: null,
    resume: null,
    skills: null,
  },
  reducers: {
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setDomain: (state, action) => {
      state.domain = action.payload;
    },
    setPhonenumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setExperience: (state, action) => {
      console.log(action.payload);
      // if (isNaN(action.payload)) {
      //   state.price = 0;
      // } else {
      //   if (action.payload === 749) {
      //     state.experience = "0-2 Years";
      //   }
      //   state.experience = action.payload;
      // }
      if (action.payload === 749) {
        state.experience = "0-2 Years";
      } else if (action.payload === 1499) {
        state.experience = "3-5 Years";
      } else if (action.payload === 1999) {
        state.experience = "5-10 Years";
      } else if (action.payload === 2499) {
        state.experience = "10+ Years";
      }
    },
    setDate: (state, action) => {
      state.dateTime = action.payload;
    },
    setPrice: (state, action) => {
      if (isNaN(action.payload)) {
        state.price = 0;
      } else {
        state.price = action.payload;
      }
    },
    setSkills: (state, action) => {
      state.skills = action.payload;
    },
    setResume: (state, action) => {
      console.log(action.payload);
      state.resume = action.payload;
    },
  },
});

export const {
  setDomain,
  setEmail,
  setExperience,
  setFullName,
  setPhonenumber,
  setDate,
  setPrice,
  setSkills,
  setResume,
} = bookInterviewSlice.actions;
export default bookInterviewSlice.reducer;
