import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    isLogged: false,
    user: null,
    userType: null,
  },
  reducers: {
    setLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    userLogout: (state) => {
      state.user = null;
    },
    setUSerType: (state, action) => {
      state.userType = action.payload.type;
    },
  },
});

export const { setLogged, setUser, userLogout, setUSerType } =
  userSlice.actions;
export default userSlice.reducer;
