import { createSlice } from "@reduxjs/toolkit";

const interviewerSlice = createSlice({
  name: "interviewerSlice",
  initialState: {
    name: "",
    email: "",
    experience: "",
    linkedIn: "",
    domain: "",
    gender: null,
    phone: null,
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setExperience: (state, action) => {
      state.experience = action.payload;
    },
    setLinked: (state, action) => {
      state.linkedIn = action.payload;
    },
    setdomain: (state, action) => {
      state.domain = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setMobile: (state, action) => {
      state.phone = action.payload;
    },
  },
});

export const {
  setName,
  setEmail,
  setExperience,
  setLinked,
  setdomain,
  setGender,
  setMobile,
} = interviewerSlice.actions;
export default interviewerSlice.reducer;
