import React from "react";
import schedule from "../assets/6257290.jpg";
import attendInterview from "../assets/3661727.jpg";
import feedback from "../assets/5393073.jpg";
import competion from "../assets/NA_October_19.jpg";
import job from "../assets/Tiny people searching for business opportunities.jpg";
import { Chrono } from "react-chrono";
import SuccessStories from "./SuccessStories";
const Testimonials = () => {
  const items = [
    {
      title: "Step-1",
      cardTitle: "Schedule a one-on-one mock interview",
      url: "http://www.history.com",
      // cardSubtitle: "Schedule a one-on-one mock interview",
      cardDetailedText:
        "Choose your experience level and share your interview focus areas in the form. Choose an available slot that fits your schedule and you're good to go!",
      media: {
        type: "IMAGE",
        source: {
          url: schedule,
        },
      },
    },
    {
      title: "Step-2",
      cardTitle: "Attend the mock interview",
      url: "http://www.history.com",
      // cardSubtitle:
      //   "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Join the mock interview through the interview invite sent to you via email and wow the interviewer!",
      media: {
        type: "IMAGE",
        source: {
          url: attendInterview,
        },
      },
    },
    {
      title: "Step-4",
      cardTitle: "Get Performance Feedback",
      url: "http://www.history.com",
      // cardSubtitle:
      //   "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Get all your doubts clarified and get a detailed analysis of your performance with actionable, insider insights and personalized tips to improve upon your weak areas. Yay!",
      media: {
        type: "IMAGE",
        source: {
          url: feedback,
        },
      },
    },
    {
      title: "Step-4",
      cardTitle: "Stand out from the competition",
      url: "http://www.history.com",
      // cardSubtitle:
      //   "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "These interviews are similar in difficulty to actual live interviews and you will be able to see how you score in several key categories. Stand out among hundreds applying for the role just like you.",
      media: {
        type: "IMAGE",
        source: {
          url: competion,
        },
      },
    },
    {
      title: "Step-4",
      cardTitle: "Get score and apply for Jobs",
      url: "http://www.history.com",
      // cardSubtitle:
      //   "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "These interviews are similar in difficulty to actual live interviews and you will be able to see how you score in several key categories. Stand out among hundreds applying for the role just like you.",
      media: {
        type: "IMAGE",
        source: {
          url: job,
        },
      },
    },
  ];
  return (
    <>
      <div className="container m-auto">
        <div className="text-center">
          <h1 className="font-bold text-[2rem] bg-clip-text text-transparent bg-gradient-to-r from-[#4646E5] to-[#006cf9] tracking-tight">
            Mastering Interview Performance
          </h1>
          <p className="text-gray-600 mt-2 text-lg leading-8 w-[60rem] m-auto">
            InterviewEasy caters to individuals at every career stage, spanning
            from beginners to executives, including both students and
            professionals. Achieve interview excellence and secure your ideal
            job, internship, research position, or specialized role in any
            industry with InterviewEasy.
          </p>
          <div className="mt-5">
            <Chrono
              items={items}
              mode="VERTICAL_ALTERNATING"
              fontSizes={{
                cardSubtitle: "0.85rem",
                cardText: "0.8rem",
                cardTitle: "1.5rem",
                cardDetailedText: "2rem",
                title: "1rem",
              }}
              classNames={{
                cardDetailedText: "text-[10rem]",
              }}
              slideShow
            />
          </div>
        </div>

        <h1 className="pb-11 text-[2rem] text-center font-bold pt-28 bg-clip-text text-transparent bg-gradient-to-r from-[#4646E5] to-[#006cf9]">
          Our Mentors
        </h1>
       
      </div>
    </>
  );
};

export default Testimonials;
