import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { Button, Heading, Input, Stack, Tag, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
const AcceptedInterviewPage = () => {
  const navigate = useNavigate();
  const [acceptedInterviews, setAcceptedInterviews] = useState([]);
  const user = getAuth().currentUser;
  const fetchAcceptedInterviews = async () => {
    if (user) {
      const userId = user.uid;
      console.log(userId);
      try {
        // Now, fetch interviews for the mentor that are accepted
        const interviewsCollection = collection(db, "bookings");
        const querySnapshot = await getDocs(
          query(
            interviewsCollection,
            where("acceptedBy", "==", userId),
            where("status", "==", "confirmed")
          )
        );

        const interviews = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(interviews);
        setAcceptedInterviews(interviews);
      } catch (error) {
        console.error("Error fetching accepted interviews for mentor:", error);
      }
    }
  };

  const handleJoinRoom = async (roomId, userId) => {
    navigate(`/room/${roomId}`);
    //   //   // const response = await fetch("http://localhost:3001/generate-token", {
    //   //   //   method: "POST",
    //   //   //   body: JSON.stringify({
    //   //   //     mentorId: user.uid,
    //   //   //     userId: userId,
    //   //   //   }),
    //   //   //   headers: { "Content-Type": "application/json" },
    //   //   // });
    //   //   // const { mentorToken, userToken } = await response.json();
  };

  useEffect(() => {
    fetchAcceptedInterviews();
  }, []);

  return (
    <div>
      <Header />

      <div className="container m-auto mt-8">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-[20px]">Accepted Interviews</h1>
          <div className="flex gap-3 w-[50%]">
            <Input placeholder="Search..." />
            <Button>Search</Button>
          </div>
        </div>
        {acceptedInterviews.map((e, i) => (
          <Card maxW="sm" className="mt-8" key={i}>
            <CardHeader>
              <Heading size="md">{e.domain}</Heading>
            </CardHeader>
            <CardBody>
              <Stack spacing={2}>
                <p>Name: {e.fullname}</p>
                <div className="flex gap-4 flex-wrap">
                  Skills:
                  {e.skills.map((f, j) => (
                    <Tag
                      className="bg-black p-1 text-white rounded-md text-[10px]"
                      key={j}
                    >
                      {f}
                    </Tag>
                  ))}
                </div>
                <Text>Mobile: {e.phoneNumber}</Text>
                <Text>
                  Time: {new Date(e.date.seconds * 1000).toLocaleString()}
                </Text>
                <Text>Experience: {e.experience}</Text>
              </Stack>
            </CardBody>
            <CardFooter className="flex gap-3">
              <Button size="sm" onClick={() => handleJoinRoom(e.id, e.userId)}>
                Join
              </Button>
              <Button colorScheme="red" size="sm">
                Cancel
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AcceptedInterviewPage;
