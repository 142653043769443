import { configureStore } from "@reduxjs/toolkit";
import modalSlices from "../slices/modalSlices";
import bookInterviewSlice from "../slices/bookInterviewSlice";
import userSlice from "../slices/userSlice";
import interviewerSlice from "../slices/interviewerSlice";
export const store = configureStore({
  reducer: {
    modalSlice: modalSlices,
    bookInterview: bookInterviewSlice,
    userSlice: userSlice,
    interviewerSlice: interviewerSlice,
  },
});
