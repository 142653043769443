import React from "react";
import Header from "../components/Header";
import {
  Box,
  Button,
  Input,
  Select,
  Stack,
  StepIndicatorContent,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Radio, RadioGroup } from "@chakra-ui/react";
import {
  setEmail,
  setName,
  setExperience,
  setdomain,
  setGender,
  setMobile,
  setLinked,
} from "../redux/slices/interviewerSlice";
// import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const BecomeInterviewPage = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const name = useSelector((state) => state.interviewerSlice.name);
  const email = useSelector((state) => state.interviewerSlice.email);
  const experience = useSelector((state) => state.interviewerSlice.experience);
  const linkedIn = useSelector((state) => state.interviewerSlice.linkedIn);
  const domain = useSelector((state) => state.interviewerSlice.domain);
  const gender = useSelector((state) => state.interviewerSlice.gender);
  const mobile = useSelector((state) => state.interviewerSlice.mobile);
  const handleSubmitInterviewer = async () => {
    try {
      if (name === "") {
        toast({
          title: "Please enter name",
          position: "top-right",
          status: "error",
          duration: 1500,
        });
        return;
      }
      if (email === "") {
        toast({
          title: "Please enter email",
          position: "top-right",
          status: "error",
          duration: 1500,
        });
        return;
      }
      if (experience === "") {
        toast({
          title: "Please select experience",
          position: "top-right",
          status: "error",
          duration: 1500,
        });
        return;
      }
      if (linkedIn === "") {
        toast({
          title: "Please enter linkedin address",
          position: "top-right",
          status: "error",
          duration: 1500,
        });
        return;
      }
      if (domain === "") {
        toast({
          title: "Please enter domain",
          position: "top-right",
          status: "error",
          duration: 1500,
        });
        return;
      }
      if (gender === "") {
        toast({
          title: "Please select gender",
          position: "top-right",
          status: "error",
          duration: 1500,
        });
        return;
      }

      const user = getAuth().currentUser;
      if (user) {
        const userId = user.uid;
        await addDoc(collection(db, "Interviewers"), {
          userId,
          name,
          email,
          experience,
          linkedIn,
          domain,
          isVerified: false,
        });
        //updating the verfied field
        // const userDocRef = doc(db, "users", user.uid);
        // const userDocSnapshot = await getDoc(userDocRef);
        //updating to the user to interviewer but we changed to once admin verify that user then we update the user as am mentor
        // await updateDoc(userDocRef, {
        //   Interviewer: true,
        // });
        // if (userDocSnapshot.exists()) {
        // User document exists, update the "Interviewer" field
        // }

        // await setDoc(collection(db, "users"), {
        //   Interviewer: true,
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-slate-100">
      <Header />
      <form className="w-[600px] flex flex-col gap-5 m-auto mt-auto">
        <h1 className="text-center font-bold text-[2rem]">
          Fill out this form
        </h1>
        <div>
          <Input
            placeholder="Name*"
            onChange={(e) => dispatch(setName(e.target.value))}
            className="mt-5"
            value={name}
          />
          <Input
            placeholder="Email*"
            onChange={(e) => dispatch(setEmail(e.target.value))}
            className="mt-5"
            value={email}
          />
          <PhoneInput
            country={"in"}
            inputClass="!h-[2.5rem] !w-full !bg-transparent !border-inherit"
            buttonClass="!bg-transparent !border-inherit"
            className="mt-5"
            value={mobile}
            onChange={(e) => dispatch(setMobile(e.target.value))}
          />
          <p className="mt-5">Gender*</p>
          <RadioGroup
            onChange={(e) => dispatch(setGender(e))}
            value={gender}
            className="mt-2"
          >
            <Stack direction="row">
              <Radio value="1">Male</Radio>
              <Radio value="0">Female</Radio>
            </Stack>
          </RadioGroup>
        </div>
        <Input
          placeholder="Enter linked in address*"
          onChange={(e) => dispatch(setLinked(e.target.value))}
          value={linkedIn}
        />
        <Input
          placeholder="Enter domain*"
          onChange={(e) => dispatch(setdomain(e.target.value))}
          value={domain}
        />
        <Select
          placeholder="Select experience"
          onChange={(e) => dispatch(setExperience(e.target.value))}
          value={experience}
        >
          <option value="0-2">0-2</option>
          <option value="2-4">2-4</option>
          <option value="4-6">4-6</option>
          <option value="6+">6+</option>
        </Select>
        <p className="font-semibold">
          Any prior experience taking interviews ??
        </p>
        <RadioGroup defaultValue="2">
          <Stack spacing={5} direction="row">
            <Radio colorScheme="green" value="1">
              Yes
            </Radio>
            <Radio colorScheme="red" value="2">
              No
            </Radio>
          </Stack>
        </RadioGroup>
        <p className="font-semibold">
          Are you ok to publish your name in our site ??
        </p>
        <RadioGroup defaultValue="2">
          <Stack spacing={5} direction="row">
            <Radio colorScheme="green" value="1">
              Yes
            </Radio>
            <Radio colorScheme="red" value="2">
              No
            </Radio>
          </Stack>
        </RadioGroup>
        <Button onClick={handleSubmitInterviewer} colorScheme="whatsapp">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default BecomeInterviewPage;
