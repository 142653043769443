import React, { useState } from "react";
import Header from "../components/Header";
import {
  Button,
  FormLabel,
  Input,
  Select,
  Textarea,
  Toast,
} from "@chakra-ui/react";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import { useSelector, useDispatch } from "react-redux";
// import { DateTimePicker } from "date-time-picker-solid";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  setDate,
  setDomain,
  setEmail,
  setExperience,
  setFullName,
  setPhonenumber,
  setPrice,
  setResume,
  setSkills,
} from "../redux/slices/bookInterviewSlice";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
import { db } from "../firebase";
import Testimonials from "../components/Testimonials";
import SuccessStories from "../components/SuccessStories";
import AccordionC from "../components/Accordion";
import Footer from "../components/Footer";
import { getAuth } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { InlineWidget } from "react-calendly";
import { useToast } from "@chakra-ui/react";
import form from "../assets/4962879.jpg";
import PhoneInput from "react-phone-input-2";
const BookInterviewPage = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const storage = getStorage();
  //   const [price, setPrice] = useState("749.00");
  const fullname = useSelector((state) => state.bookInterview.fullName);
  const email = useSelector((state) => state.bookInterview.email);
  const domain = useSelector((state) => state.bookInterview.domain);
  const phoneNumber = useSelector((state) => state.bookInterview.phoneNumber);
  const experience = useSelector((state) => state.bookInterview.experience);
  const dateTime = useSelector((state) => state.bookInterview.dateTime);
  const amount = useSelector((state) => state.bookInterview.price);
  const skills = useSelector((state) => state.bookInterview.skills);
  const [date, setDate] = useState(new Date());
  const [resumeFile, setResumeFile] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setloading] = useState(false);
  const handlePhoneInput = (value) => {
    dispatch(setPhonenumber(value));
  };
  const handleDateTime = (value) => {
    setDate(value);
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setResumeFile(file);
  };
  const handleBookInterview = async (e) => {
    const user = getAuth().currentUser;
    const currency = "INR";
    if (fullname === "") {
      toast({
        title: "Please enter fullname",
        position: "top-right",
        status: "error",
        duration: 1500,
      });
      return;
    }
    if (email === "") {
      toast({
        title: "Please enter email",
        position: "top-right",
        status: "error",
        duration: 1500,
      });
      return;
    }
    if (domain === "") {
      toast({
        title: "Please enter domain",
        position: "top-right",
        status: "error",
        duration: 1500,
      });
      return;
    }
    if (skills === "") {
      toast({
        title: "Please enter domain",
        position: "top-right",
        status: "error",
        duration: 1500,
      });
      return;
    }
    if (phoneNumber === "") {
      toast({
        title: "Please enter mobile number",
        position: "top-right",
        status: "error",
        duration: 1500,
      });
      return;
    }
    if (experience === null) {
      toast({
        title: "Please select experience",
        position: "top-right",
        status: "error",
        duration: 1500,
      });
      return;
    }
    if (!resumeFile) {
      toast({
        title: "Please upload a resume",
        position: "top-right",
        status: "error",
        duration: 1500,
      });
      return;
    }

    if (user) {
      setloading(true);
      const userId = user.uid;
      const storageRef = ref(getStorage(), `resumes/${resumeFile.name}`);
      // const resumeDataString = new TextDecoder().decode(resumeFile);
      try {
        const responce = await fetch(
          "https://my-app-backend-theta.vercel.app/order",
          {
            method: "POST",
            body: JSON.stringify({
              amount,
              currency,
              receipt: "order_rcptid_11",
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        // console.log(responce);
        const order = await responce.json();

        var options = {
          key: "rzp_test_tgzDWXWEe8jxyk", // Enter the Key ID generated from the Dashboard
          amount: order.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: order.order.currency,
          name: "intervieweasy.in", //your business name
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          order_id: order.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: async function (response) {
            await uploadBytesResumable(storageRef, resumeFile);

            await addDoc(collection(db, "bookings"), {
              userId,
              fullname,
              email,
              domain,
              phoneNumber,
              date,
              experience,
              amount,
              skills,
              mentorSelectionStatus: "automatic",
              status: "pending",
              resumeUrl: await getDownloadURL(
                uploadBytesResumable(storageRef, resumeFile).snapshot.ref
              ),
            });
            toast({
              title: "Booking successfull",
              position: "bottom-right",
              status: "success",
              duration: 2000,
            });
            dispatch(setFullName(""));
            dispatch(setEmail(""));
            dispatch(setPhonenumber(""));
            dispatch(setSkills(""));
            dispatch(setDomain(""));

            await fetch(
              "https://my-app-backend-theta.vercel.app/triggerEndpoint",
              {
                method: "POST",
                body: JSON.stringify({
                  bookingDomain: domain,
                  name: fullname,
                }),
                headers: { "Content-Type": "application/json" },
              }
            )
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
            // console.log("Booking added successfully");
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
            setloading(false);
          },
          prefill: {
            //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            name: "Gaurav Kumar", //your customer's name
            email: "gaurav.kumar@example.com",
            contact: "9000090000", //Provide the customer's phone number for better conversion rates
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        rzp1.open();
        e.preventDefault();
      } catch (error) {
        console.error("Error adding booking:", error);
      }
    }
  };
  return (
    <div>
      <Header />
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        {/* <InlineWidget url="https://calendly.com/udaybalram12/30min" /> */}
        <div className="container flex flex-col m-auto items-center">
          <div className="w-[70%] m-auto py-24">
            <h1 className="font-bold text-[2.5rem] tracking-tight text-gray-900 text-center">
              Don't miss out on this unique opportunity to elevate your
              interview game!
            </h1>
            <p className="text-[1rem] text-[#0009] pt-3 text-center">
              Level up your interview skills with our mock interviews –
              guaranteed results or your money back. Invest in yourself today
              for career success tomorrow!
            </p>
            <button className="rounded-md block m-auto mt-5 bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Get started
            </button>
          </div>
          <div className="flex">
            <img src={form} alt="fill" className="w-[50%] object-contain" />
            <form className="flex m-auto w-[50%]  p-14 gap-12">
              {/* <p>
            Price:<span>{price} Rs</span>
          </p> */}
              {/* <div className="w-[50%]">
            <img src="" alt="img" />
          </div> */}
              <div className="w-[100%]">
                <FormLabel htmlFor="name" className="text-[0.875rem]">
                  Name
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Enter Your full name "
                  onChange={(e) => dispatch(setFullName(e.target.value))}
                  value={fullname}
                />
                <FormLabel htmlFor="email" className="text-[0.875rem] mt-2">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  placeholder="Enter Your email "
                  onChange={(e) => dispatch(setEmail(e.target.value))}
                  value={email}
                />
                <FormLabel htmlFor="domain" className="text-[0.875rem] mt-2">
                  Domain
                </FormLabel>
                <Input
                  id="domain"
                  placeholder="Enter Your domain"
                  onChange={(e) => dispatch(setDomain(e.target.value))}
                  value={domain}
                  errorBorderColor="#4F46E5"
                />
                <FormLabel htmlFor="skills" className="text-[0.875rem] mt-2">
                  Skills
                </FormLabel>
                <Textarea
                  id="skills"
                  placeholder="Enter Your skills"
                  onChange={(e) => {
                    const s = e.target.value.split(",");
                    dispatch(setSkills(s));
                  }}
                  value={skills}
                  errorBorderColor="#4F46E5"
                />
                <p className="text-[13px]">
                  <span className="font-bold">Note:</span>
                  Enter you skills separated by comma(,)
                </p>
                <FormLabel htmlFor="phone" className="text-[0.875rem] mt-2">
                  Phone
                </FormLabel>
                {/* <PhoneInput
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  className="focus:outline-[#61dafb]"
                  onChange={handlePhoneInput}
                  value={phoneNumber}
                /> */}
                <PhoneInput
                  country={"in"}
                  inputClass="!h-[2.5rem] !w-full !bg-transparent !border-inherit"
                  buttonClass="!bg-transparent !border-inherit"
                  onChange={handlePhoneInput}
                  value={phoneNumber}
                  // isValid={(value, country) => {
                  //   if (value.match(/12345/)) {
                  //     toast({
                  //       title: "Invalid mobile number",
                  //       status: "error",
                  //       duration: 1500,
                  //       position: "top-right",
                  //     });
                  //   } else if (value.match(/1234/)) {
                  //     toast({
                  //       title: "Invalid mobile number",
                  //       status: "error",
                  //       duration: 1500,
                  //       position: "top-right",
                  //     });
                  //   } else {
                  //     return true;
                  //   }
                  // }}
                />
                {/* <div>
              <DateTimePicker />
            </div> */}

                <FormLabel htmlFor="date" className="text-[0.875rem] mt-2">
                  Pick Slot
                </FormLabel>
                <DateTimePicker
                  id="date"
                  value={date}
                  onChange={handleDateTime}
                  className="h-[2.5rem] w-[100%]"
                />
                {/* <Input
              placeholder="Select Date and Time"
              size="md"
              type="datetime-local"
              value={dateTime}
              onChange={handleDateTime}
            /> */}
                <FormLabel
                  htmlFor="experience"
                  className="text-[0.875rem] mt-2"
                >
                  Experience
                </FormLabel>
                <Select
                  required
                  id="experience"
                  onChange={(e) => {
                    console.log(e.target.value);
                    dispatch(setExperience(parseInt(e.target.value)));
                    dispatch(setPrice(parseInt(e.target.value)));
                  }}
                  placeholder="Select experience"
                >
                  <option value="749.00">0-2 Years</option>
                  <option value="1499.00">3-5 Years</option>
                  <option value="1999.00">5-10 Years</option>
                  <option value="2499.00">10+ Years</option>
                </Select>
                <FormLabel htmlFor="resume" className="text-[0.875rem] mt-2">
                  Resume
                </FormLabel>
                <Input type="file" id="resume" onChange={handleFileChange} />
                <h1 className="text-[1.5rem] font-bold pt-3">
                  Rs:{isNaN(amount) ? 0 : amount}/-
                </h1>
                <Button
                  onClick={handleBookInterview}
                  marginTop="10px"
                  colorScheme="red"
                  display={"block"}
                  margin={"auto"}
                  isLoading={loading}
                >
                  Book now
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="pt-[30px]">
        <h1 className="font-bold text-[2rem] text-center mb-6">
          Meet Our Success mates
        </h1>
        <SuccessStories />
        <AccordionC />
        <Footer />
      </div>
    </div>
  );
};

export default BookInterviewPage;
