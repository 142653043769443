import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
const AccordionC = () => {
  return (
    <div className="container w-[50%] m-auto">
      <h1 className="font-bold text-center text-[2rem] pt-24 pb-10">
        <span>FAQ'S</span> for mock interviews
      </h1>
      <Accordion allowToggle>
        <AccordionItem border={0}>
          <h2>
            <AccordionButton
              _expanded={{ bg: "tomato", color: "white" }}
              // className="h-[50px] border rounded-tl-md"
            >
              <Box as="span" flex="1" textAlign="left">
                What happens after you book the Interview?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "tomato", color: "white" }}>
              <Box as="span" flex="1" textAlign="left">
                What are the consequences of canceling a scheduled interview?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "tomato", color: "white" }}>
              <Box as="span" flex="1" textAlign="left">
                What is the recommended timeframe for scheduling a mock
                interview in advance?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionC;
