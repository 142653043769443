import React, { useEffect } from "react";
import Header from "../components/Header";
import MainContent from "../components/MainContent";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import SuccessStories from "../components/SuccessStories";

const Dashboard = () => {
  return (
    <div>
      <Header />
      <MainContent />
      <Testimonials />
      <SuccessStories />
      <Footer />
    </div>
  );
};

export default Dashboard;
